<template>
  <b-container>
    <b-row class="contact-ctn pt-5">
      <b-col class="text-center">
        <p>DISPONIBLE PRÓXIMAMENTE</p>
        <b-btn @click="signOut">Cerrar sesión</b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    signOut () {
      this.$cookies.remove('token')
      this.$cookies.remove('role')
      this.$router.push('/')
      window.location.reload();
    }
  },
  mounted () {
    if (!this.$cookies.get('token')) {
      this.$router.push('/login')
    } else if (this.$cookies.get('role') !== 'User') {
      this.$router.push('/admin')
    }
    /* this.axios.get('/users').then(response => {
      this.name = response.data.name
      this.email = response.data.email
      this.dni = response.data.dni
    }) */
  }
}
</script>

<style>

</style>